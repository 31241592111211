import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import DescriptionIcon from '@material-ui/icons/Description';
import EmailIcon from '@material-ui/icons/Email';


const useStyles = makeStyles({
  pos: {
    padding: '10px 10px',
  },
});

const query = graphql`
query HomePageQuery {
  courses: allCoursesJson {
    nodes {
      type
      courses
    }
  }
  positions: allPositionsJson {
    nodes {
      company
      position
      date
      desc
    }
  }
  media: allMediaJson {
    nodes {
      title
      icon
      url
    }
  }
}
`

const buildPosition = ({ company, position, date }, key) => {
  return (
    <div style={{ margin: "2.2em" }} key={key}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
      >
        <Typography variant="p" component="h4" gutterBottom style={{color:'black'}}>
          <b>{position}</b>
        </Typography>
        <Typography variant="p" component="h4">
          {date}
        </Typography>
      </Grid>
      <Typography variant="h4" component="h2">
        <b>{company}</b>
      </Typography>

      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </div>
  )
}

const buildCourse = ({ type, courses }, key) => {
  return (
    <div style={{ margin: "2.2em" }} key={key}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
      >
        <Typography variant="p" component="h4" gutterBottom style={{color:'black'}}>
          <b>{type}</b>
        </Typography>
        <Typography variant="p" component="h4">
        </Typography>
      </Grid>
      <Typography variant="h5" component="h5">
        <b>{courses}</b>
      </Typography>

      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </div>
  )
}

const buildMedia = ({ title, icon, color, url }, key) => {
  const components = {
    linkedin: LinkedInIcon,
    github: GitHubIcon,
    description: DescriptionIcon,
    email: EmailIcon
  };
  const IconType = components[icon]
  return (
    <Button
      component={Link}
      href={url}
      rel='noopener noreferrer'
      variant="contained"
      target="_blank"
      startIcon={<IconType />}
      key={key}
      style={{ margin: "2.2em", background: '#7E57C2', color:"white" }}
    >
      {title}
    </Button>
  )
}

const IndexPage = () => {
  const classes = useStyles();
  const { courses, positions, media } = useStaticQuery(query);
  return (
    <Layout>
      <SEO title="Kevin Shen" />
      <h1>Kevin Shen</h1>
      <Typography variant="h5" component="p" gutterBottom>
        <b>4th Year @ University of Toronto. Statistics, Machine Learning & Data Mining Stream.</b>
      </Typography>
      <br></br>
      <Paper className={classes.pos} elevation={0} variant="outlined">
        {
          positions.nodes.map(({ company, position, date }, i) => (
            buildPosition({ company, position, date }, i)
          ))
        }
      </Paper>
      <br></br>
      <Paper className={classes.pos} elevation={0} variant="outlined">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
        >
          {
            media.nodes.map(({ title, icon, color, url }, i) => (
              buildMedia({ title, icon, color, url }, i)
            ))
          }
        </Grid>
      </Paper>
      <br></br>
      <Paper className={classes.pos} elevation={0} variant="outlined">
        {
          courses.nodes.map(({ type, courses }, i) => (
            buildCourse({ type, courses }, i)
          ))
        }
      </Paper>
    </Layout>
  );
}

export default IndexPage
